<script>
import Layout from "../../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required, maxLength } from "vuelidate/lib/validators";/* 
import Multiselect from "vue-multiselect";
 */
export default {
  page: {
    title: "Nouveau Periode Repas",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader/* , Multiselect */ },
  data() {
    return {
      title: "Nouveau Periode Repas",
      typeForm: {
        name: "",
      },
      userStructuresTypes: [],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      types: [],
      items: [
        {
          text: "Paramètres",
          href: "/",
        },
        {
          text: "Contrats",
          href: "/",
        },
        {
          text :"Restauration",
          href : "/"
        },
        {
          text: "Periode de repas",
          href: "/",
        },
        {
          text: "Nouveau",
          active: true,
        },
      ],
    };
  },

  validations: {
    typeForm: {
      name: { required, maxLength: maxLength(64) },
    },
  },

  created() {
    //this.fetchTypes();
  },

  methods: {
   /*  fetchTypes(){
      this.$http
        .post("/contracts/warranty_types/getTypes")

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.types = res.data.original.types;
              break;

            case 500:
                this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
            this.$toast.error(error.message);
        })
        .finally(() => {});
    }, */
    formSubmit() {
      this.submitted = true;
      this.$v.$touch()
      if(!this.$v.$invalid){
      var router = this.$router;
      this.$http
        .post("/base/restauration/PeriodeRepas/store", this.typeForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              router.push({ name: "base.settings.prestations.restauration.periodeRepas.index" });
              break;

            case 500:
                this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
            this.$toast.error(error.message);
        })
        .finally(() => {});
    }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <b-alert show variant="info">
                <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
                obligatoires sont marqués avec (*)
              </b-alert>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="formrow-email-input">Désignation *</label>
                    <input
                      id="projectname"
                      v-model="typeForm.name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.typeForm.name.$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="submitted && $v.typeForm.name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.typeForm.name.required">La désignation est obligatoire.</span>
                      <span v-if="!$v.typeForm.name.maxLength" >La taille du champs est limitée a 64 charactères.</span>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <button
                  class="btn btn-primary"
                  type="submit"
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
